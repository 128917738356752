<template>
    <div class="login-container">
        <div class="login-bg">
            <!--            <img src="@/assets/images/login_left_bg01.png" alt="">-->
            <!--            <img src="@/assets/images/login_left_bg02.png" alt="" class="login-bg-center">-->
            <!--            <img src="@/assets/images/login_left_bg03.png" alt="" class="login-bg-right">-->
        </div>
        <div class="login-box">
            <div class="login-content">
                <img src="@/assets/images/login_logo.png" alt="">
                <div class="tc">
                    <p class="logo-title">
                        中央市场农产品交易
                    </p>
                    <p class="logo-title">
                        数据服务管理平台
                    </p>
                </div>
            </div>
            <el-form ref="loginMd5Form" :model="loginMd5Form" class="login-form" label-position="left" :rules="loginRules" size="large">

            </el-form>
            <el-form ref="loginForm" :model="loginForm" class="login-form" label-position="left" :rules="loginRules" size="large">


                <el-form-item prop="username">
                    <el-input placeholder="账号"
                              v-model="loginForm.account"
                              @focus="getFocus"
                              auto-complete="on">
                        <template slot="prepend">
                            <i class="el-icon-mobile-phone"></i>
                        </template>
                    </el-input>
                </el-form-item>

                <el-form-item prop="password">
                    <el-input placeholder="密码"
                              v-model="loginForm.password"
                              @keyup.enter.native="handleLogin"
                              type="password"
                    >
                        <template slot="prepend">
                            <i class="el-icon-lock"></i>
                        </template>
                        <!--                        <template slot="append">-->
                        <!--                            <el-button type="text" :loading="loading"-->
                        <!--                                       :disabled="loading"-->
                        <!--                                       class="show-pwd"-->
                        <!--                                       @click="showPwd">{{loading?`${i>10?i:'0'+i}秒`:'获取验证码'}}-->
                        <!--                            </el-button>-->
                        <!--                        </template>-->
                    </el-input>
                </el-form-item>

                <el-button type="primary" class="login-btn" style="width:100%;margin-bottom:30px;"
                           @click.native.prevent="handleLogin">
                    登录
                </el-button>


            </el-form>
        </div>

    </div>
</template>

<script>
    import { validUsername } from '@/utils/validate'
    import { mapActions, mapGetters } from 'vuex'
    import router from '@/router'
    import { menuList } from '../../../mock'
    import Http from "@/service/http";
    import EForm from '@/components/EForm'

    import md5 from 'crypto-js/md5';

    export default {
        name: 'Login',
        data() {
            let Base64 = require('js-base64').Base64
            const validateUsername = (rule, value, callback) => {
                if (!validUsername(value)) {
                    callback(new Error('Please enter the correct user name'))
                } else {
                    callback()
                }
            }
            const validatePassword = (rule, value, callback) => {
                
                if (value.length < 8 || value.length > 24) {
                    callback(new Error('密码必须为8-24个字符'))
                } else {
                    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W_]).{8,24}$/;
                    if(regex.test(value)){
                        callback();
                    }else{
                        callback(new Error('密码必须为8-24位字母,数字和符号'))
                    }
                    
                }
            }
            return {
                loginForm: {
                    account: '',
                    password: '',
                    grantType: 'password',
                    refreshToken: '',
                },
                loginMd5Form: {
                    account: '',
                    password: '',
                    grantType: 'password',
                    refreshToken: '',
                },
                loginRules: {
                    account: [{ required: true, trigger: 'blur', validator: validateUsername }],
                    password: [{ required: true, trigger: 'blur', validator: validatePassword }]
                },
                loading: false,
                passwordType: '',
                redirect: undefined,
                i: 60
            }
        },
        watch: {
            $route: {
                handler: function (route) {
                    this.redirect = route.query && route.query.redirect
                },
                immediate: true
            }
        },
        computed: {
            ...mapGetters(['menuList'])
        },
        created() {
            // window.location.reload()
            let data = this.$route.query
            if (data.err) {
                this.$messageBox.alert(data.errorMsg, '提示', {
                    confirmButtonText: '确定',
                    type: 'warning',
                    callback: () => {
                        sessionStorage.removeItem('areaInfo');
                        this.$router.replace('/login')
                    }
                });
            }

        },
        components: {
            EForm
        },
        methods: {
            ...mapActions([
                'setMenu',
                'setUserInfo',
                'setButton'
            ]),
            getFocus() {

            },
            async showPwd() {
                if (!this.loginForm.mobile) {
                    this.$message.error('手机号不能为空')
                    return false
                } else {
                    if (!this.loading) {
                        this.loading = true
                        let time = setInterval(() => {
                            this.i--
                            if (this.i === 1) {
                                clearInterval(time)
                                this.loading = false
                                this.i = 60
                            }
                        }, 1000)
                        let res = await Http.sendVerifyCode({ mobile: this.loginForm.mobile })
                        if (res.flag == 1) {
                            this.loginForm.verify_code = res.data.verify_code
                        } else if (res.flag == 2222) {
                            clearInterval(time)
                            this.loading = false
                            this.i = 60
                        }

                    }
                }
            },
            async handleLogin() {
                
                this.loginMd5Form.account = Base64.encode(this.loginForm.account).toString()
                this.loginMd5Form.password = Base64.encode(this.loginForm.password).toString()
                //alert(JSON.stringify(this.loginForm))
                //alert(JSON.stringify(this.loginMd5Form))
                //return
                let res = await Http.login(this.loginMd5Form)
                if (res.code == 200) {
                    let data = res.data
                    this.setUserInfo(data)
                    this.routes()
                    // this.setMenu(res.data.menu_data)
                    // if (res.data.menu_data.length == 0) {
                    //   this.$message.error('您尚未配置任何权限，请联系管理员进行配置！')
                    //
                    // } else {
                    //   this.$router.push({path: this.menuList[0].route_path})
                    // }
                    // this.setMenu(menuList)

                    // this.$router.push({path:'/index'})
                    // this.$router.push({name:'/index'})
                    // this.$router.push({path: this.menuList[0].route_path})

                }else if(res.code == 400){
                    this.$message.error(res.msg);
                }


            },
            async routes() {
                let res = await Http.routes()
                if (res.code == 200) {
                    this.setMenu(res.data)
                    if (res.data[0].code != 'Index') {
                        this.setButton(res.data[0].childrens.length > 0 ? res.data[0].childrens[0].buttonIdCn : [])
                    }
                    this.$router.push({ name: res.data[0].code })

                    // window.location.reload()
                }
            }

        }
    }
</script>

<style lang="scss" scoped>
    .login-container {
        min-height: calc(100vh);
        width: 100%;
        background-color: #f5f5f7;
        overflow: hidden;
        display: flex;
        text-align: left;

        .login-btn {
            background-color: #2D683C;
            border-color: #2D683C;
        }

        .login-bg {
            width: calc(100vw - 650px);
            height: calc(100vh);
            position: relative;
            background: url("../../assets/images/login_bg.png") no-repeat;
            background-size: 100% 100%;

            .login-bg-center {
                width: 558px;
                height: 542px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            .login-bg-right {
                position: absolute;
                right: 0;
                bottom: 0;
                transform: translateX(-50px);
            }
        }

        .login-box {
            position: fixed;
            right: 0;
            top: 0;
            width: 650px;
            height: 100%;
            background-color: #fff;

            .login-content {
                margin-top: 200px;
                display: flex;
                justify-content: center;

                .logo-title {
                    font-size: 40px;
                    color: #2D683C;
                    font-weight: bold;
                    line-height: 60px;
                }

            }


        }

        .login-form {
            position: relative;
            width: 520px;
            max-width: 100%;
            padding: 80px 35px 0;
            margin: 0 auto;
            overflow: hidden;
            box-sizing: border-box;

            .el-form-item__content {
                display: flex;
            }
        }

        .tips {
            font-size: 14px;
            color: #fff;
            margin-bottom: 10px;

            span {
                &:first-of-type {
                    margin-right: 16px;
                }
            }
        }

        .svg-container {
            padding: 6px 5px 6px 15px;
            color: #999;
            vertical-align: middle;
            width: 30px;
            display: inline-block;
        }

        .title-container {
            position: relative;

            .title {
                font-size: 26px;
                color: #fff;
                margin: 0px auto 40px auto;
                text-align: center;
                font-weight: bold;
            }
        }

        .show-pwd {
            padding: 0 10px;
            font-size: 14px;
            color: #4d68ff;
            cursor: pointer;
        }

    }

</style>
